import { AnyAction } from 'redux';

const FIRST_USER_PRICE = 11260; // hardcoded for now, needs to be fetched from somewhere else
const SECOND_USER_PRICE = 3230; // hardcoded for now, needs to be fetched from somewhere else
const EXTRA_USER_PRICE = 5110; // hardcoded for now, needs to be fetched from somewhere else

export interface CalculationData {
  firstLicensePrice: number;
  secondLicensePrice: number;
  extraLicensePrice: number;
  licensesAmount: number;
  licenseTypeSelected: string;
}

interface State {
  calculationData: CalculationData;
}

const initialState: State = {
  calculationData: {
    firstLicensePrice: FIRST_USER_PRICE,
    secondLicensePrice: SECOND_USER_PRICE,
    extraLicensePrice: EXTRA_USER_PRICE,
    licensesAmount: 1,
    licenseTypeSelected: 'small',
  },
};

type Action = {
  type: string;
  payload: Partial<CalculationData>;
};

const storeReducer = (
  state: State = initialState,
  action: AnyAction
): State => {
  switch (action.type) {
    case '@store/UPDATE_CALCULATION_DATA':
      return {
        ...state,
        calculationData: {
          ...state.calculationData,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

const updateCalculationData = (payload: Partial<CalculationData>): Action => ({
  type: '@store/UPDATE_CALCULATION_DATA',
  payload,
});

export { storeReducer, updateCalculationData, Action };
