import React, {Suspense, lazy, DetailedHTMLProps, HTMLAttributes} from 'react';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner } from 'react-bootstrap';
// // @ts-ignore
// import Spinner from './components/Spinner.jsx';
import { Provider } from 'react-redux';
import InfoBanner from './components/InfoBanner'

import store from './store/store'

import { defineCustomElements, JSX as LocalJSX } from '@web-standards/moonfish-components/dist/loader';
import '@web-standards/moonfish-styles/dist/index.css';
import '@web-standards/moonfish-styles/dist/themes/crescent.css';
import { ampli } from './ampli';
import {parse} from 'cookie';
import { isProdEnv } from './utils';


// Moonfish with TypeScript Initialization
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

type StencilProps<T> = { [P in keyof T]: Omit<T[P], 'ref'> };

type ReactProps<T> = {
  [P in keyof T]: DetailedHTMLProps<HTMLAttributes<T[P]>, T[P]>;
};

type StencilToReact<
T = LocalJSX.IntrinsicElements,
// eslint-disable-next-line no-undef
U = HTMLElementTagNameMap
> = StencilProps<T> & ReactProps<U>;

declare global {
  export namespace JSX {
    // eslint-disable-next-line no-unused-vars
    interface IntrinsicElements extends StencilToReact {}
  }
}

defineCustomElements();

const StorePage = lazy(() => import('./pages/StorePage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const CheckoutPage = lazy(() => import('./pages/CheckoutPage'));
const ConfirmationPage = lazy(() => import('./pages/ConfirmationPage'));
const ContactSalesPage = lazy(() => import('./pages/ContactSalesPage'));


// Amplitude Initialization
const ampliAPIEndpointV2 = `https://a.blaw.com/2/httpapi`;
ampli.load({
  environment: (isProdEnv()) ? 'productionbloomberglaw' :'developmentbloomberglaw',
  client: {
    configuration: {
      serverUrl: ampliAPIEndpointV2
    },
  }
});
const cookies = parse(document.cookie);
const uaString = window.navigator?.userAgent;
const bid = cookies?.bid;
ampli.identify(undefined, {userAgent: uaString, bid})

const App: React.FC = () => {
  return (
    <div className="moonfish main" data-theme="crescent" data-color-scheme="light">
      <div className="main__wrapper">
        <Provider store={store}>
          {/* <InfoBanner></InfoBanner> */}
          <Suspense fallback={<Spinner />}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<StorePage />} />
                <Route path="/checkout" element={<CheckoutPage/>} />
                <Route path="/confirmation" element={<ConfirmationPage/>} />
                <Route path="/contact-sales" element={<ContactSalesPage/>} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </BrowserRouter>
          </Suspense>
        </Provider>

      </div>
    </div>
  );
};

export default App;
