import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { storeReducer, Action } from './storeReducer';

export const rootReducer = {
  storeReducer,
};

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware()
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
